import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Strong } from '../../typography';
import { COLORS } from '../../../helpers/constants/styleguide';
import TooltipFloater, { offsetFromReferenceEdge } from '../../TooltipFloater';
import Icon, { Cross } from '../../Icon';

const CovidTooltip = ({ top, size, text, kind }) => (
  <IconContainer top={top}>
    <TooltipFloater
      kind={kind}
      placement="bottom"
      offset={offsetFromReferenceEdge(2)}
      content={() => (
        <Text small>
          <Strong>COVID-19 update:</Strong> {text}
        </Text>
      )}
    >
      <StyledIcon
        fill={COLORS.covidGreen}
        SvgComponent={Cross}
        width={size}
        height={size}
      />
    </TooltipFloater>
  </IconContainer>
);

CovidTooltip.propTypes = {
  top: PropTypes.number,
  size: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  leftOffset: PropTypes.number,
  width: PropTypes.number,
  /** Used for mixpanel tracking */
  kind: PropTypes.string,
};

CovidTooltip.defaultProps = {
  text: '',
  size: 18,
};

const StyledIcon = styled(Icon)`
  cursor: pointer;

  &:hover,
  .isOpen > & {
    opacity: 0.6;
  }
`;

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  top: ${({ top }) => top || 0}px;
`;

export default CovidTooltip;
