import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { Text } from '../../typography';
import InfoTooltip from '../../tooltipTriggers/InfoTooltip';

import { MobileSize, COLORS } from '../../../helpers/constants/styleguide';

const Checkbox = ({
  name,
  label,
  labelSize,
  tooltipText,
  tooltipWidth,
  onChange,
  numberOfCol,
  validate,
  checked,
}) => {
  return (
    <Container numberOfCol={numberOfCol} key={name}>
      <CheckboxInput
        type="checkbox"
        onChange={onChange}
        validate={validate}
        id={name}
        data-testid={`input-checkbox-${name}`}
        checked={checked}
      />
      <FocusCircle />
      <CustomCheckbox htmlFor={name}>
        <Text size={labelSize} paragraph>
          {label}
          {tooltipText ? (
            <InfoTooltip text={tooltipText} width={tooltipWidth} top={2} />
          ) : null}
        </Text>
      </CustomCheckbox>
    </Container>
  );
};

const FocusCircle = styled.span`
  margin-left: -6px;
  margin-top: -1px;
  z-index: 0;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: absolute;
`;

const CheckboxInput = styled(Field)`
  opacity: 0;
  margin-top: 4px;
  position: absolute;
  z-index: -1;

  & ~ label:before {
    background-color: ${COLORS.white};
  }

  & ~ label:after {
    content: none;
  }

  &:checked ~ label:after {
    content: '';
  }

  &:checked ~ label:before {
    background-color: ${COLORS.jade};
    border-color: ${COLORS.jade};
  }

  :focus + span {
    background-color: ${COLORS.grey};
  }
`;

const CustomCheckbox = styled.label`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  position: relative;
  cursor: pointer;

  &:before {
    flex: none;
    position: relative;
    content: '';
    display: inline-block;
    margin-right: 8px;
    color: ${COLORS.medGrey};
    border-radius: 3px;

    height: 16px;
    width: 16px;
    border: 2px solid;
    top: 5px;
  }

  &:after {
    position: absolute;
    content: '';
    display: inline-block;

    height: 6px;
    width: 12px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    left: 3px;
    top: 9px;
    border-color: ${COLORS.white};

    transform: rotate(-45deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: 16px;
  margin-bottom: 12px;
  justify-content: left;
  &:hover span {
    background-color: ${COLORS.grey};
  }

  width: ${({ numberOfCol }) =>
    numberOfCol > 1 ? `calc(100% / ${numberOfCol} - 8px)` : 'auto'};

  @media (max-width: ${MobileSize}) {
    width: 100% !important;
    margin-right: 0 !important;
  }

  ${({ numberOfCol }) => {
    if (numberOfCol) {
      const evenMargin = numberOfCol % 2 === 0 ? 0 : 16;
      const oddMargin = numberOfCol % 2 === 0 ? 16 : 0;

      return `
      &:nth-child(even){
        margin-right: ${evenMargin}px;
      }
      &:nth-child(odd){
        margin-right: ${oddMargin}px;
      }
      &:first-child{
        margin-right: ${numberOfCol > 1 ? '16px' : '0px'};
      }
     `;
    }

    return ``;
  }};
`;

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelSize: PropTypes.string,
  onChange: PropTypes.func,
  numberOfCol: PropTypes.number,
  validate: PropTypes.func,
  checked: PropTypes.bool,
};

export default Checkbox;
